export const RETRIEVE_TEMPLATES = "RETRIEVE_TEMPLATE"
export const INFO_TEMPLATES = "INFO_TEMPLATES"
export const DELETE_TEMPLATES = "DELETE_TEMPLATE"
export const COPY_TEMPLATES = "COPY_TEMPLATE"
export const CREATE_TEMPLATES = "CREATE_TEMPLATES"
export const UPDATE_TEMPLATES = "UPDATE_TEMPLATES"
export const RETRIEVE_TEMPLATES_SENDER = "RETRIEVE_TEMPLATES_SENDER"
export const CLEAR_DATA_TEMPLATE = "CLEAR_DATA_TEMPLATE"
export const GET_LIST_PARAMETER = "GET_LIST_PARAMETER"
export const RETRIEVE_TEMPLATES_SENDERS = "RETRIEVE_TEMPLATES_SENDERS"
